import { graphql } from '~/integrations/dato/utils/graphql'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js'

export const TeamMembersSectionModuleFragment = graphql(
  /* GraphQL */ `
    fragment TeamMembersSectionModuleFragment on TeamMembersSectionRecord {
      id
      __typename
      body {
        ... on BodyModuleRecord {
          id
          ...BodyModuleFragment
        }
      }
      members {
        ... on MemberRecord {
          id
          fullname
          biography {
            value
          }
          picture {
            ...ImageModuleFragment
          }
          jobPosition
        }
      }
    }
  `,
  [ImageModuleFragment],
)
