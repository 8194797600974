import { graphql } from '~/integrations/dato/utils/graphql.js'

export const MapModuleFragment = graphql(/* GraphQL */ `
  fragment MapModuleFragment on MapModuleRecord {
    darkmode
    geolocationInherit
    interactive
    markers {
      geolocation {
        latitude
        longitude
      }
    }
    advanced
  }
`)
