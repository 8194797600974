import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import type { TEventPage } from '~/components/pages/event/event.type.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'

import { StoryPageFragment } from '~/components/pages/story/story.fragment.js'
import type { TStoryPage } from '~/components/pages/story/story.type'

export async function getStoryPage(
  slug: string,
  locale: string,
): Promise<TStoryPage> {
  const query = graphql(
    /* GraphQL */ `
      {
        storyPage (filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          ...StoryPageFragment
        }
      }
    `,
    [StoryPageFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `story-page-${slug}-${locale}`,
    transform(data) {
      return data.storyPage ?? data
    },
  })
}
