import { graphql } from '~/integrations/dato/utils/graphql'

import { PagesSlugsFragment } from '~/components/pages/pagesSlugs.fragment.js'
import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'

export const ServicePageFragment = graphql(
  /* GraphQL */ `
    fragment ServicePageFragment on ServicePageRecord {
      _seoMetaTags {
        tag
        attributes
        content
      }
      id
      slug
      title
      tagline
      backgroundImage {
        url
        alt
        title
      }
      featuredImage {
        url
        alt
        title
      }
      description {
        value
      }
      taglineContact {
        value
      }
      geolocation {
        latitude
        longitude
      }
      mediaContact {
        ...ImageModuleFragment
      }
      openingHours {
        ... on OpeningHourRecord {
          title
          text {
            value
          }
        }
      }
      sections {
        ... on GridSectionRecord {
          ...GridSectionFragment
        }
        ...BlocksFragment
      }
      _allSlugLocales {
        locale
        value
      }
    }
  `,
  [GridSectionFragment, BlocksFragment, PagesSlugsFragment],
)
