import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import type { THomepage } from '~/components/pages/homepage/homepage.type.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'

import { HomepageFragment } from '~/components/pages/homepage/homepage.fragment.js'

export async function getHomepage(locale: string): Promise<THomepage> {
  const query = graphql(
    /* GraphQL */ `
      {
        homepage(locale: ${locale}) {
          ...HomepageFragment
        }
      }
    `,
    [HomepageFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `homepage-${locale}`,
    transform(data) {
      return data.homepage ?? data
    },
  })
}
