import { LinkModuleFragment } from '~/components/ui/link/link.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'

export const ButtonModuleFragment = graphql(
  /* GraphQL */ `
    fragment ButtonModuleFragment on ButtonModuleRecord {
      lnf
      color
      link {
        ... on LinkModuleRecord {
          ...LinkModuleFragment
        }
      }
    }
  `,
  [LinkModuleFragment],
)
