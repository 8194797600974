import { PageFragment } from '~/components/pages/page/page.fragment.js'
import type { TPage } from '~/components/pages/page/page.type.js'
import { graphql } from '~/integrations/dato/utils/graphql'
import { useDatoQuery } from '../composables/useDatoQuery.js'

export function getPage(slug: string, locale: string): Promise<TPage> {
  const query = graphql(
    /* GraphQL */ `
      {
        page(filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          ...PageFragment
        }
      }
    `,
    [PageFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `page-${slug}-${locale}`,
    transform(data) {
      return data.page ?? data
    },
  })
}
