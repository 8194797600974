import { graphql } from '~/integrations/dato/utils/graphql.js'
import { TabFragment } from '~/components/ui/tab/tab.fragment'

export const TabsModuleFragment = graphql(
  /* GraphQL */ `
    fragment TabsModuleFragment on TabsModuleRecord {
      id
      __typename
      internalName
      tabs {
        ... on TabRecord {
          ...TabFragment
        }
      }
    }
  `,
  [TabFragment],
)
