import { graphql } from '~/integrations/dato/utils/graphql'

import { PagesSlugsFragment } from '~/components/pages/pagesSlugs.fragment.js'
import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'

export const EventPageFragment = graphql(
  /* GraphQL */ `
    fragment EventPageFragment on EventPageRecord {
      _seoMetaTags {
        tag
        attributes
        content
      }
      id
      slug
      title
      excerpt {
        value
        links {
          ...PagesSlugsFragment
        }
      }
      location {
        value
        links
      }
      date
      backgroundImage {
        url
        alt
        title
      }
      featuredImage {
        url
        alt
        title
      }
      sections {
        ... on GridSectionRecord {
          ...GridSectionFragment
        }
        ...BlocksFragment
      }
      _allSlugLocales {
        locale
        value
      }
    }
  `,
  [GridSectionFragment, BlocksFragment, PagesSlugsFragment],
)
