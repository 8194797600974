import { graphql } from '~/integrations/dato/utils/graphql'

import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'

export const PageFragment = graphql(
  /* GraphQL */ `
    fragment PageFragment on PageRecord {
      _seoMetaTags {
        tag
        attributes
        content
      }
      id
      title
      slug
      sections {
        ... on GridSectionRecord {
          ...GridSectionFragment
        }
        ...BlocksFragment
      }
      _allSlugLocales {
        locale
        value
      }
    }
  `,
  [GridSectionFragment, BlocksFragment],
)
