import { ServicePageFragment } from '~/components/pages/service/service.fragment.js'
import type { TServicePage } from '~/components/pages/service/service.type.js'
import { graphql } from '~/integrations/dato/utils/graphql'
import { useDatoQuery } from '../composables/useDatoQuery.js'

export async function getServicePage(
  slug: string,
  locale: string,
): Promise<TServicePage> {
  const query = graphql(
    /* GraphQL */ `
      {
        servicePage (filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          ...ServicePageFragment
        }
      }
    `,
    [ServicePageFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `service-page-${slug}-${locale}`,
    transform(data) {
      return data.servicePage ?? data
    },
  })
}
