import { graphql } from '~/integrations/dato/utils/graphql'

export const PagesSlugsFragment = graphql(
  /* GraphQL */ `
    fragment PagesSlugsFragment on RecordInterface {
      ... on RecordInterface {
        __typename
        id
      }
      ... on EventPageRecord {
        slug
        title
      }
      ... on HomepageRecord {
        slug
        title
      }
      ... on PageRecord {
        slug
        title
      }
      ... on ServicePageRecord {
        slug
        title
      }
    }
  `,
  [],
)
