import { __deepSearch } from '@lotsof/sugar/object'
import { HomepageFragment } from '~/components/pages/homepage/homepage.fragment.js'
import { PageFragment } from '~/components/pages/page/page.fragment.js'
import { type TSearchPage } from '~/components/pages/search/search.type.js'
import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'
import { type TSearchResults } from '~/components/system/search/search.type.js'
import { graphql } from '~/integrations/dato/utils/graphql'
import { useDatoQuery } from '../composables/useDatoQuery.js'

function getTitleFromSeoMetas(metas: any[]): string {
  const title = (metas ?? []).find((meta) => meta.tag === 'title')
  return title?.content
}
function getImageFromSeoMetas(metas: any[]): string {
  const image = (metas ?? []).find(
    (meta) => meta.attributes?.property === 'og:image',
  )
  return image?.attributes?.content
}
function getDescriptionFromSeoMetas(metas: any[]): string {
  const image = (metas ?? []).find(
    (meta) => meta.attributes?.property === 'og:description',
  )
  return image?.attributes?.content
}

export async function getPage(locale: string) {
  const query = graphql(
    /* GraphQL */ `
      {
        searchPage(locale: ${locale}) {
          id
          title
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment],
  )

  return useDatoQuery<TSearchPage, any>(query, {
    key: `search-${locale}`,
    transform(data) {
      return data.searchPage ?? data
    },
  })
}

export async function search(
  search: string,
  locale: string,
): Promise<TSearchResults> {
  const query = graphql(
    /* GraphQL */ `
      {
        allPages(locale: ${locale}) {
          ...PageFragment
        }
        homepage(locale: ${locale}) {
          ...HomepageFragment
        }
      }
    `,
    [PageFragment, HomepageFragment],
  )

  // query dato to get all pages
  // @TODO  add cache
  let pages: Record<string, any> = await useDatoQuery(query, {
    key: 'search',
    cache: false,
    transform(data) {
      return data.value ?? data
    },
  })

  // wait until ref is resolved
  const { resolveRef } = useRef()
  pages = await resolveRef(pages)

  // compile all pages into an array  (flatten)
  const allPages: any[] = []
  for (let [key, value] of Object.entries(pages)) {
    if (Array.isArray(value)) {
      allPages.push(...value)
    } else {
      allPages.push(value)
    }
  }

  // @ts-ignore
  let pagesArray = allPages
    .map((page: any) => {
      let title = page.title ?? getTitleFromSeoMetas(page._seoMetaTags)
      let excerpt =
        page.excerpt ?? getDescriptionFromSeoMetas(page._seoMetaTags)
      let image = page.featuredImage ?? getImageFromSeoMetas(page._seoMetaTags)
      if (typeof image === 'string') {
        image = { url: image }
      } else if (image && !image.url) {
        image.url = image
      }

      return {
        id: page.id,
        title,
        excerpt,
        slug: page.slug,
        image,
        sections: page.sections,
      }
    })
    .sort((a, b) => {
      return a.title.localeCompare(b.title)
    })

  // filter pages by search
  if (search !== '*' && pagesArray?.length) {
    pagesArray = pagesArray.filter((page: any) => {
      const res = __deepSearch(page, (item: any) => {
        if (item.key.endsWith('slug') && item.key.split('.').length === 1) {
          return true
        }
        if (typeof item.value !== 'string') {
          return false
        }
        return item.value.match(new RegExp(search, 'i'))
      })

      if (res.slug && Object.keys(res).length > 1) {
        return true
      }
      return false
    })
  }

  // return the results
  return pagesArray
}
