import { graphql } from '~/integrations/dato/utils/graphql'

export const ExternalVideoFragment = graphql(
  /* GraphQL */ `
    fragment ExternalVideoFragment on VideoField {
      thumbnailUrl
      url
      title
      width
      height
      provider
      providerUid
    }
  `,
  [],
)
