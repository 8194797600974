export type TResolveRefSettings = {
  interval: number
  timeout: number
}

const refApi = {
  async resolveRef(
    r: any,
    settings?: Partial<TResolveRefSettings>,
  ): Promise<any> {
    const finalSettings: TResolveRefSettings = {
      interval: 100,
      timeout: -1,
      ...(settings ?? {}),
    }

    return new Promise(async (resolve) => {
      let found = false

      // if it's not a ref
      // return it directly
      if (!r.dep) {
        return resolve(r)
      }

      // timeout
      if (finalSettings.timeout > 0) {
        setTimeout(() => {
          found = true
          resolve(undefined)
        }, finalSettings.timeout)
      }

      while (!found) {
        if (r.value) {
          found = true
          resolve(r.value as T)
        } else {
          await new Promise((r) => setTimeout(r, finalSettings.interval))
        }
      }
    })
  },
}

export default function useRef(): typeof refApi {
  return refApi
}
