import { graphql } from '~/integrations/dato/utils/graphql'

export const PresetFragment = graphql(
  /* GraphQL */ `
    fragment PresetFragment on PresetRecord {
      id
      name
      description
      tags
      category
      enabled
      preview {
        url
      }
      preset
    }
  `,
  [],
)
