import type { TLocales } from '~/components/layout/locales/locales.type.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'
import { graphql } from '../utils/graphql'

export async function getLocales(): Promise<TLocales> {
  const localesFragments = graphql(
    /* GraphQL */ `
      query {
        _site {
          locales
        }
      }
    `,
    [],
  )
  const data = await useDatoQuery(localesFragments, {
    key: 'locales',
  })

  return {
    locales: data.value?._site.locales ?? [],
  }
}
