import { BodyModuleFragment } from '~/components/ui/body/body.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'

export const HeroSectionFragment = graphql(
  /* GraphQL */ `
    fragment HeroSectionFragment on HeroSectionRecord {
      theme
      backgroundImage {
        url
        alt
        title
      }
      featuredImage {
        url
        alt
        title
      }
      body {
        ... on BodyModuleRecord {
          ...BodyModuleFragment
        }
      }
      advanced
    }
  `,
  [BodyModuleFragment],
)
