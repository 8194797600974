import { graphql } from '~/integrations/dato/utils/graphql.js'

export const TagFragment = graphql(
  /* GraphQL */ `
    fragment TagFragment on TagRecord {
      id
      __typename
      label
      value
      backgroundColor {
        hex
      }
      textColor {
        hex
      }
    }
  `,
  [],
)
