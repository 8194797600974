import { graphql } from '~/integrations/dato/utils/graphql.js'
import { TabGridSectionFragment } from './tabGrid.fragment'
import { BlocksFragment } from '~/components/system/blocks/blocks.fragment'

export const TabFragment = graphql(
  /* GraphQL */ `
    fragment TabFragment on TabRecord {
      id
      __typename
      title
      sections {
        ... on GridSectionRecord {
          ...TabGridSectionFragment
        }
        ...BlocksFragment
      }
    }
  `,
  [BlocksFragment, TabGridSectionFragment],
)
