// @ts-ignore
import { __camelCase } from '@lotsof/sugar/string'

export default function graphqlMenuIdPluralize(str: string): string {
  const firstLetter = str.split('').shift()
  const restLetters = __camelCase(str.slice(1, -1))
  const lastLetter = str.split('').pop()

  if (['i', 'y'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}ies`
  }
  if (['s'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}es`
  }
  if (['h'].includes(lastLetter as string)) {
    return `all${firstLetter?.toUpperCase()}${restLetters}hes`
  }

  return `all${firstLetter?.toUpperCase()}${restLetters}${lastLetter}s`
}
