import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'

export const TabGridSectionFragment = graphql(
  /* GraphQL */ `
    fragment TabGridSectionFragment on GridSectionRecord {
      id
      __typename
      layout
      theme
      area1 {
        ...BlocksFragment
      }
      area2 {
        ...BlocksFragment
      }
      area3 {
        ...BlocksFragment
      }
      # If you uncomment this line, you will get an error because the complexity is too high
      area4 {
        ...BlocksFragment
      }
      advanced
    }
  `,
  [BlocksFragment],
)
