import { PresetFragment } from '~/components/system/preset/preset.fragment.js'
import { type TPreset } from '~/components/system/preset/preset.type'
import { type TPresetsFilters } from '~/components/system/presets/presets.type'
import { graphql } from '~/integrations/dato/utils/graphql'
import { useDatoQuery } from '../composables/useDatoQuery.js'

export function filterPresets(
  presets: TPreset[],
  filters?: TPresetsFilters,
): TPreset[] {
  return presets.filter((preset: TPreset) => {
    if (filters?.enabled !== undefined) {
      return preset.enabled === filters.enabled
    } else if (!preset.enabled) {
      return false
    }
    if (!filters) {
      return true
    }
    if (
      filters.category &&
      preset.category.toLowerCase() !== filters.category.toLowerCase()
    ) {
      return false
    }
    if (
      filters.tags?.length &&
      !preset.tags.filter((tag) => {
        return filters.tags?.includes(tag)
      }).length
    ) {
      return false
    }
    return true
  })
}

export async function getPresets(
  filters?: TPresetsFilters,
): Promise<TPreset[]> {
  const query = graphql(
    /* GraphQL */ `
      {
        allPresets {
          ...PresetFragment
        }
      }
    `,
    [PresetFragment],
  )
  return useDatoQuery<any, any>(query, {
    transform: async (data) => {
      const finalPresets = filterPresets(
        data.allPresets?.map((preset: TPreset) => {
          return {
            name: preset.name,
            description: preset.description,
            tags: (preset.tags as any).split(','),
            category: preset.category,
            enabled: preset.enabled,
            preview: {
              url: preset.preview.url,
            },
            preset: preset.preset,
          }
        }),
        filters,
      )
      return finalPresets
    },
  })
}
