import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'
import { TabsModuleFragment } from '~/components/ui/tabs/tabs.fragment'

export const GridSectionFragment = graphql(
  /* GraphQL */ `
    fragment GridSectionFragment on GridSectionRecord {
      id
      __typename
      layout
      theme
      area1 {
        ...BlocksFragment

        ... on TabsModuleRecord {
          ...TabsModuleFragment
        }
      }
      area2 {
        ...BlocksFragment

        ... on TabsModuleRecord {
          ...TabsModuleFragment
        }
      }
      area3 {
        ...BlocksFragment

        ... on TabsModuleRecord {
          ...TabsModuleFragment
        }
      }
      area4 {
        ...BlocksFragment

        ... on TabsModuleRecord {
          ...TabsModuleFragment
        }
      }
      backgroundSettings {
        media {
          ... on MediaModuleRecord {
            ...MediaModuleFragment
          }
        }
        backdrop
      }
      advanced
    }
  `,
  [BlocksFragment, TabsModuleFragment],
)
