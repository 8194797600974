import { graphql } from '~/integrations/dato/utils/graphql.js'

export const SpacerModuleFragment = graphql(
  /* GraphQL */ `
    fragment SpacerModuleFragment on SpacerModuleRecord {
      id
      __typename
      size
    }
  `,
  [],
)
