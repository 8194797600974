import { graphql } from '~/integrations/dato/utils/graphql'

import { PagesSlugsFragment } from '~/components/pages/pagesSlugs.fragment.js'
import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'
import { TagFragment } from '~/components/ui/tag/tag.fragment.js'

export const StoryPageFragment = graphql(
  /* GraphQL */ `
    fragment StoryPageFragment on StoryPageRecord {
      _seoMetaTags {
        tag
        attributes
        content
      }
      id
      slug
      title
      tags {
        ...TagFragment
      }
      author {
        ... on MemberRecord {
          id
          fullname
          picture {
            ...ImageModuleFragment
          }
          jobPosition
        }
      }
      publicationDate
      excerpt {
        value
        links {
          ...PagesSlugsFragment
        }
      }
      featuredImage {
        url
        alt
        title
      }
      sections {
        ... on GridSectionRecord {
          ...GridSectionFragment
        }
        ...BlocksFragment
      }
      _allSlugLocales {
        locale
        value
      }
    }
  `,
  [GridSectionFragment, BlocksFragment, PagesSlugsFragment, TagFragment],
)
