import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import type { TEventPage } from '~/components/pages/event/event.type.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'

import { EventPageFragment } from '~/components/pages/event/event.fragment.js'

export async function getEventPage(
  slug: string,
  locale: string,
): Promise<TEventPage> {
  const query = graphql(
    /* GraphQL */ `
      {
        eventPage (filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          ...EventPageFragment
        }
      }
    `,
    [EventPageFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `event-page-${slug}-${locale}`,
    transform(data) {
      return data.eventPage ?? data
    },
  })
}
