import { PagesSlugsFragment } from '~/components/pages/pagesSlugs.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'

export const TextModuleFragment = graphql(
  /* GraphQL */ `
    fragment TextModuleFragment on TextModuleRecord {
      text {
        value
        links {
          ...PagesSlugsFragment
        }
      }
      alignment
      advanced
    }
  `,
  [PagesSlugsFragment],
)
