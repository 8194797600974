import { graphql } from '~/integrations/dato/utils/graphql'

import { BlocksFragment } from '~/components/system/blocks/blocks.fragment.js'
import { GridSectionFragment } from '~/components/system/grid/grid.fragment.js'

export const HomepageFragment = graphql(
  /* GraphQL */ `
    fragment HomepageFragment on HomepageRecord {
      _seoMetaTags {
        tag
        attributes
        content
      }
      id
      title
      slug
      featuredImage {
        url
        alt
        title
      }
      featuredVideo {
        url
        alt
        title
      }
      sections {
        ... on GridSectionRecord {
          ...GridSectionFragment
        }
        ...BlocksFragment
      }
      _allSlugLocales {
        locale
        value
      }
    }
  `,
  [GridSectionFragment, BlocksFragment],
)
