import { graphql } from '~/integrations/dato/utils/graphql'

export const AccordionItemModuleFragment = graphql(
  /* GraphQL */ `
    fragment AccordionItemModuleFragment on AccordionItemModuleRecord {
      id
      title
      text {
        value
      }
      expanded
      advanced
    }
  `,
  [],
)

export const AccordionModuleFragment = graphql(
  /* GraphQL */ `
    fragment AccordionModuleFragment on AccordionModuleRecord {
      id
      __typename
      items {
        ...AccordionItemModuleFragment
      }
      singleExpand
      advanced
    }
  `,
  [AccordionItemModuleFragment],
)
