import { ExternalVideoFragment } from '~/components/ui/externalVideo/externalVideo.fragment.js'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'
export const MediaModuleFragment = graphql(
  /* GraphQL */ `
    fragment MediaModuleFragment on MediaModuleRecord {
      image {
        ...ImageModuleFragment
      }
      video {
        url
        video {
          mp4Url
          thumbnailUrl
          streamingUrl
        }
      }
      externalVideo {
        ...ExternalVideoFragment
      }
      legend
      advanced
    }
  `,
  [ImageModuleFragment, ExternalVideoFragment],
)
