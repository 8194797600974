import { graphql } from '~/integrations/dato/utils/graphql'

export const FormModuleFragment = graphql(
  /* GraphQL */ `
    fragment FormModuleFragment on FormModuleRecord {
      id
      __typename
      json
    }
  `,
  [],
)