import { MediaModuleFragment } from '~/components/ui/media/media.fragment.js'
import { graphql } from '~/integrations/dato/utils/graphql'

export const SliderModuleFragment = graphql(
  /* GraphQL */ `
    fragment SliderModuleFragment on SliderModuleRecord {
      id
      __typename
      slides {
        id
        __typename
        ... on MediaModuleRecord {
          ...MediaModuleFragment
        }
      }
      advanced
    }
  `,
  [MediaModuleFragment],
)
